import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import DocumentModal from "../../components/DocumentModal";
import UserService from "../../service/user.service";
import Avatar from "react-avatar";
import DropdownCustom from "../../components/DropdownCustom";
import Swal from "sweetalert2";
import Loader from "../../assets/images/loader.gif";
import { GoVerified } from "react-icons/go";
import { GoUnverified } from "react-icons/go";
import {
  FaCheck,
  FaRegTrashAlt,
  FaUserAlt,
  FaUserAltSlash,
} from "react-icons/fa";
import { IoClose, IoCloseOutline } from "react-icons/io5";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [deleteModals, setDeleteModals] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [isBlockUnblockOpen, setIsBlockUnblockOpen] = useState(false);
  const [blockUnBlockText, setBlockUnBlockText] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const handleDocumentOpen = (type, id, name) => {
    setDeleteId(id);
    setDeleteModals(true);
  };

  const handleBlockUnblock = (type, id) => {
    setIsBlockUnblockOpen(true);
    setBlockUnBlockText(type);
  };

  const getUserDetails = async () => {
    try {
      const reponse = await UserService.getAllUser("", "");
      // const reponseBlockUser = await UserService.blockUserList();
      // const newData = [...reponse.data.data, ...reponseBlockUser.data.data];

      setData(reponse.data.data.find((item) => item._id === id));
    } catch (error) {
      showMessage(error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  useEffect(() => {
    getUserDetails();
  }, [id]);

  const handleClickClose = (type) => {
    if (type === "blockUnBlock") {
      setIsBlockUnblockOpen(false);
    } else {
      setDeleteModals(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await UserService.deleteUser(deleteId);
      console.log("🚀 ~ handleDelete ~ response:", response);
      showMessage(response.data.message, "success");
      navigate(-1);
      handleClickClose("delete");
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const handleBlock = async () => {
    try {
      if (blockUnBlockText === "block") {
        const response = await UserService.blockUser(id);
        showMessage(response.data.message, "success");
      } else if (blockUnBlockText === "unblock") {
        const response = await UserService.unBlockUser(id);
        showMessage(response.data.message, "success");
      }
      handleClickClose("blockUnBlock");
      getUserDetails();
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-[calc(100vh-180px)]">
          <img src={Loader} alt="Loader" className="w-14 h-14" />
        </div>
      ) : (
        <div className="panel grid gap-5 xl:grid-cols-2 grid-cols-1">
          <div className="max-w-2xl">
            <div className="flex justify-between items-center border-primary/20">
              <h5 className="text-lg font-semibold dark:text-white-light">
                User Profile
              </h5>

              <div className="flex gap-2 items-center">
                <div
                  className="cursor-pointer h-8 w-8 px-1 flex items-center justify-center border border-primary/20 rounded"
                  onClick={() => handleDocumentOpen("delete", id)}
                >
                  <AiFillDelete className="h-5 w-5 hover:text-danger/70 text-danger duration-300" />
                </div>

                {data?.blockByAdmin ? (
                  <div
                    className="cursor-pointer h-8 px-1 flex items-center gap-1 justify-center border border-primary/20 rounded text-primary hover:text-primary/70"
                    onClick={() => handleBlockUnblock("unblock", id)}
                  >
                    <FaUserAlt className="h-5 w-5 duration-300" />{" "}
                    <span className="font-bold">Unblock</span>
                  </div>
                ) : (
                  <div
                    className="cursor-pointer h-8 px-1 flex items-center gap-1 justify-center border border-primary/20 rounded text-primary hover:text-primary/70"
                    onClick={() => handleBlockUnblock("block", id)}
                  >
                    <FaUserAltSlash className="h-6 w-6 duration-300" />{" "}
                    <span className="font-bold">Block</span>
                  </div>
                )}
              </div>
            </div>

            {data?.profileImage?.length > 0 ? (
              <div className="pt-5">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {data?.profileImage?.map((img, index) => (
                    <SwiperSlide className="!flex !justify-center !items-center !h-[500px] !w-full">
                      <img
                        src={img}
                        alt="image"
                        className="rounded-xl w-fit object-cover h-full"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <Swiper
                  style={{ cursor: "pointer" }}
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper pt-4"
                >
                  {data?.profileImage?.map((img, index) => (
                    <SwiperSlide className="mt-3">
                      <img
                        className="w-full h-36 object-cover"
                        src={img}
                        alt="image"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <div className="flex justify-center h-full items-center text-lg">
                <p>Profile Images Not Found</p>
              </div>
            )}
          </div>
          <div className="border-primary/20">
            <div className="flex gap-3 mb-4">
              {data?.isVerify ? (
                <p className="px-2 py-1.5 bg-blue-600 text-white rounded flex gap-2 items-center">
                  Verified <GoVerified className="text-[16px]" />
                </p>
              ) : (
                <p className="px-2 py-1.5 bg-danger text-white rounded flex gap-2 items-center">
                  Not Verified <GoUnverified className="text-[16px]" />
                </p>
              )}
              {data?.isProfileComplete ? (
                <p className="px-2 py-1.5 bg-green-600 text-white rounded flex gap-2 items-center">
                  Profile Completed <FaCheck className="text-[16px]" />
                </p>
              ) : (
                <p className="px-2 py-1.5 bg-danger text-white rounded flex gap-2 items-center">
                  Profile Not Completed <IoClose className="text-[16px]" />
                </p>
              )}
              {data?.isActive ? (
                <p className="px-2 py-1.5 bg-blue-600 text-white rounded flex gap-2 items-center">
                  Activated
                </p>
              ) : (
                <p className="px-2 py-1.5 bg-danger text-white rounded flex gap-2 items-center">
                  Not Activated
                </p>
              )}
            </div>

            <div>
              <div className="pb-4 grid sm:grid-cols-3 gap-1">
                <span className="font-semibold">Name</span>
                <p className="col-span-2">
                  <strong className="hidden sm:inline-block">: </strong>{" "}
                  {data?.first_name && data?.last_name
                    ? `${data?.first_name} ${data?.last_name}`
                    : data?.first_name
                    ? data?.first_name
                    : data?.last_name
                    ? data?.last_name
                    : "--"}
                </p>
              </div>
              {data?.email && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Email</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.email}
                  </p>
                </div>
              )}
              {data?.mobileNo && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Mobile Number</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong> +
                    {data?.countryCode} {data?.mobileNo}
                  </p>
                </div>
              )}
              {data?.age && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Age</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.age}
                  </p>
                </div>
              )}
              {data?.bio && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Bio</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.bio}
                  </p>
                </div>
              )}
              {data?.gender && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Gender</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.gender}
                  </p>
                </div>
              )}
              {data?.city && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Address</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.city}, {data?.state}, {data?.country}
                  </p>
                </div>
              )}
              {data?.interests?.length > 0 && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Interests</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.interests.join(", ")}
                  </p>
                </div>
              )}
              {data?.fetishes?.length > 0 && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Fetishes</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.fetishes.join(", ")}
                  </p>
                </div>
              )}
              {data?.maritalStatus && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Marital Status</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.maritalStatus}
                  </p>
                </div>
              )}
              {data?.relationInterest && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Relation Interest</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.relationInterest}
                  </p>
                </div>
              )}

              {data?.workEmail && (
                <div className="pb-4 grid sm:grid-cols-3 gap-1">
                  <span className="font-semibold">Work Email</span>
                  <p className="col-span-2">
                    <strong className="hidden sm:inline-block">: </strong>{" "}
                    {data?.workEmail}
                  </p>
                </div>
              )}
              <div className="pb-4 grid sm:grid-cols-3 gap-1">
                <span className="font-semibold">Block By Admin</span>
                <p className="col-span-2">
                  <strong className="hidden sm:inline-block">: </strong>{" "}
                  {data?.blockByAdmin ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModals && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <div className="flex justify-between items-center flex-col pb-6 gap-7">
                  <div className="border-2 border-danger p-2.5 rounded-full">
                    <IoCloseOutline className="text-danger text-4xl" />
                  </div>
                  <h5 className="tab:text-xl text-base font-semibold">
                    Delete Conformation
                  </h5>
                  <p>Are you sure you want to delete this user ?</p>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="submit"
                    onClick={() => handleClickClose("delete")}
                    className="btn bg-white text-primary font-bold border border-primary hover:bg-primary hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleDelete}
                    className="btn bg-danger text-white font-bold border border-danger hover:bg-transparent hover:text-danger duration-200 text-sm w-full"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Block/Unblock */}
      {isBlockUnblockOpen && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <div className="flex justify-between items-center flex-col pb-6 gap-7">
                  <div
                    className={`border-2 ${
                      blockUnBlockText === "block"
                        ? "border-danger"
                        : "border-success"
                    } p-2.5 rounded-full`}
                  >
                    {blockUnBlockText === "block" ? (
                      <FaUserAltSlash className="text-danger text-4xl" />
                    ) : (
                      <FaUserAlt className="text-success text-4xl" />
                    )}
                  </div>
                  <h5 className="tab:text-xl text-base font-semibold">
                    {blockUnBlockText === "block"
                      ? "Block Conformation"
                      : "Unblock Conformation"}
                  </h5>
                  <p>
                    {blockUnBlockText === "block"
                      ? "Are you sure you want to block this user ?"
                      : "Are you sure you want to unblock this user ?"}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="submit"
                    onClick={() => handleClickClose("blockUnBlock")}
                    className="btn bg-white text-primary font-bold border border-primary hover:bg-primary hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleBlock}
                    className={`btn ${
                      blockUnBlockText === "block"
                        ? "bg-danger border-danger hover:text-danger"
                        : "bg-success border-success hover:text-success"
                    } text-white font-bold border hover:bg-transparent duration-200 text-sm w-full`}
                  >
                    {blockUnBlockText === "block" ? "Block" : "Unblock"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
