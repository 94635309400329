import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toggleRTL } from "../../store/themeConfigSlice";
import IconMail from "../../components/Icon/IconMail";
import IconLockDots from "../../components/Icon/IconLockDots";
import Logo from "../../assets/images/logo.svg";
import { IoEye, IoEyeOff } from "react-icons/io5";
import GradientBG from "../../assets/images/auth/bg-gradient.png";
import ComingSoonObject1 from "../../assets/images/auth/coming-soon-object1.png";
import ComingSoonObject2 from "../../assets/images/auth/coming-soon-object2.png";
import ComingSoonObject3 from "../../assets/images/auth/coming-soon-object3.png";
import PolygonObject from "../../assets/images/auth/polygon-object.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import OTPInput from "otp-input-react";
import AuthenticationService from "../../service/auth.service";
import { setLoggedIn, setToken } from "../../store/slice/auth";

const VerifyOtp = () => {
  const { state } = useLocation();
  const [OTP, setOTP] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    otp: "",
  };

  const schema = yup.object().shape({
    otp: yup.string().required("Enter your password"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        console.log("values", values);
        const { otp } = values;
        const response = await AuthenticationService.verifyOtp(
          state.email,
          otp
        );
        dispatch(setLoggedIn(true));
        dispatch(setToken(response.token));
        localStorage.setItem("isLoggedIn", JSON.stringify(true));

        const toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          showCloseButton: true,
          customClass: {
            popup: `color-${"success"}`,
          },
        });
        toast.fire({
          title: "User login successfully",
        });
        navigate("/user");
        action.resetForm();
        window.location.reload();
      } catch (error) {
        // const toast = Swal.mixin({
        //   toast: true,
        //   position: "top-end",
        //   showConfirmButton: false,
        //   timer: 3000,
        //   showCloseButton: true,
        //   customClass: {
        //     popup: `color-${"danger"}`,
        //   },
        // });
        // toast.fire({
        //   title: error,
        // });
      }
    },
  });

  const handleChange = (OTP) => {
    setOTP(OTP);
    formik.setFieldValue("otp", OTP);
  };

  return (
    <div>
      <div className="absolute inset-0">
        <img
          src={GradientBG}
          alt="image"
          className="h-full w-full object-cover"
        />
      </div>

      <div className="relative flex min-h-screen items-center justify-center  bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
        <img
          src={ComingSoonObject1}
          alt="image"
          className="absolute left-0 top-1/2 h-full max-h-[893px] -translate-y-1/2"
        />
        <img
          src={ComingSoonObject2}
          alt="image"
          className="absolute left-24 top-0 h-40 md:left-[30%]"
        />
        <img
          src={ComingSoonObject3}
          alt="image"
          className="absolute right-0 top-0 h-[300px]"
        />
        <img
          src={PolygonObject}
          alt="image"
          className="absolute bottom-0 end-[28%]"
        />
        <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
          <div className="relative flex flex-col justify-evenly rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
            <div className="flex items-center justify-center">
              <img src={Logo} alt="Logo" className="w-36 h-36" />
            </div>
            <div className="mx-auto w-full max-w-[440px]">
              <div className="mb-10 flex flex-col gap-2">
                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl text-center">
                  Verify OTP
                </h1>
                <p className="text-base font-bold leading-normal text-white-dark text-center">
                  Enter Your OTP which sent to the {state?.email}
                </p>
              </div>
              <form
                className="space-y-5 dark:text-white"
                onSubmit={formik.handleSubmit}
              >
                <div>
                  <label htmlFor="Email">Enter Your OTP</label>
                  <div className="flex justify-center">
                    <OTPInput
                      value={OTP}
                      onChange={handleChange}
                      autoFocus
                      OTPLength={6}
                      inputClassName="inputStyle"
                      otpType="number"
                      name="otp"
                      disabled={false}
                      secure
                    />
                  </div>
                  {formik.errors.otp && formik.touched.otp && (
                    <p className="text-red-500 ms-2 text-[14px]">
                      {formik.errors.otp}
                    </p>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn bg-primary text-white font-bold !mt-6 w-full border border-primary hover:bg-transparent hover:text-primary duration-200 rounded-full text-base"
                >
                  Verify OTP
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
