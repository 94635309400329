import { get } from "lodash";
import serverCall from "../serverCall";

const login = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  return serverCall
    .post(`/admin/login?email=${email}&password=${password}`)
    .then((response) => {
      const data = get(response, "data", null);
      console.log("🚀 ~ .then ~ data:", data)
      if (data) {
        const user = data.data.email;
        const token = data.token;

        console.log("data", data);

        const userAndToken = {
          user,
          token,
        };
        localStorage.setItem("shhinder-store", JSON.stringify(userAndToken));
      }
      return data;
    });
};

const sendOtp = async (email) => {
  return serverCall.get(`/auth/sendOtp?email=${email}`).then((response) => {
    const data = get(response, "data", null);
    console.log("🚀 ~ returnserverCall.get ~ data:", data);
    // if (data) {
    //   const user = data.data.email;
    //   const token = data.token;

    //   console.log("data", data);

    //   const userAndToken = {
    //     user,
    //     token,
    //   };
    //   localStorage.setItem("shhinder-store", JSON.stringify(userAndToken));
    // }
    return data;
  });
};

const verifyOtp = async (email, otp) => {
  return serverCall
    .get(`/auth/verify-email?email=${email}&otp=${otp}`)
    .then((response) => {
      const data = get(response, "data", null);
      console.log("🚀 ~ returnserverCall.get ~ data:", data);
      if (data) {
        const user = data.data.email;
        const token = data.token;

        const userAndToken = {
          user,
          token,
        };
        localStorage.setItem("shhinder-store", JSON.stringify(userAndToken));
      }
      return data;
    });
};

const AuthenticationService = {
  login,
  sendOtp,
  verifyOtp,
};

export default AuthenticationService;
