import React, { useEffect, useState } from "react";
import { DataTable } from "mantine-datatable";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { FaUserAlt, FaUserAltSlash } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import UserService from "../../service/user.service";
import Avatar from "react-avatar";
import CustomPagination from "../../components/Layouts/CustomPagination";

const UserTable = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [documentModal, setDocumentModal] = useState();
  const [deleteModals, setDeleteModals] = useState();
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState([]);
  const [isBlockUnblockOpen, setIsBlockUnblockOpen] = useState(false);
  const [blockUnBlockText, setBlockUnBlockText] = useState("");
  const [id, setId] = useState("");
  const [totalCount, setTotalCount] = useState(0);

  const handleDocumentOpen = (type, id, name) => {
    setDeleteId(id);
    setDeleteModals(true);
  };

  const handleBlockUnblock = (type, id) => {
    setIsBlockUnblockOpen(true);
    setBlockUnBlockText(type);
    setId(id);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please fill the name"),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      const body = {
        categoryId: values.id,
        name: values.name,
      };
      try {
        if (!values.id) {
          // const response = await CategoryService.addCategory(values.name);
          // console.log("addCategory", response);
          // showMessage(response.data.message, "success");
        } else {
          // const response = await CategoryService.updateCategory(body);
          // showMessage(response.data.message, "success");
        }
        getAllCategory();
        setIsUpdate(false);
        handleClickClose("add");
        setSelectedImage([]);
        formik.resetForm();
      } catch (error) {
        showMessage(error.response.data.message, "error");
      }
    },
  });

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      // const reponse = await UserService.getAllUser();
      // const reponseBlockUser = await UserService.blockUserList();
      // const newData = [...reponse.data.data, ...reponseBlockUser.data.data];
      // setData(newData);

      const reponse = await UserService.getAllUser(page, pageSize);
      setData(reponse.data.data);
      setTotalCount(reponse.data.totalUsers);
    } catch (error) {
      showMessage(error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [page, pageSize]);

  const handleNavigate = (id) => {
    navigate(`/user/${id}`);
  };

  const handleClickClose = (type) => {
    if (type === "add") {
      setIsUpdate(false);
      formik.resetForm();
      setDocumentModal(false);
      setSelectedImage([]);
    } else if (type === "blockUnBlock") {
      setIsBlockUnblockOpen(false);
    } else {
      setDeleteModals(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await UserService.deleteUser(deleteId);
      showMessage(response.data.message, "success");
      getAllUsers();
      handleClickClose("delete");
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const handleBlock = async () => {
    try {
      if (blockUnBlockText === "block") {
        const response = await UserService.blockUser(id);
        showMessage(response.data.message, "success");
      } else if (blockUnBlockText === "unblock") {
        const response = await UserService.unBlockUser(id);
        showMessage(response.data.message, "success");
      }
      handleClickClose("blockUnBlock");
      getAllUsers();
    } catch (error) {
      showMessage(error.response.data.message, "error");
    }
  };

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    setSelectedImage(URL.createObjectURL(file));
  };

  return (
    <>
      <div className="panel">
        <div className="flex items-center justify-between gap-4 border-b border-primary/20 pb-5">
          <h5 className="text-lg font-semibold dark:text-white-light">Users</h5>
        </div>
        <div className="datatables user-table">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={data}
            columns={[
              {
                accessor: "id",
                title: "ID",
                // sortable: true,
                render: ({ id }, index) => (
                  <div>
                    <strong>{(page - 1) * pageSize + index + 1}</strong>
                  </div>
                ),
              },
              {
                accessor: "profileImage",
                title: "Profile",
                render: ({ profileImage, first_name, email }) => (
                  <div className="w-full">
                    {profileImage[0] ? (
                      <img
                        src={profileImage[0]}
                        width="80"
                        height="80"
                        className="object-cover w-16 h-16 rounded-full"
                      />
                    ) : (
                      <Avatar
                        name={first_name ? first_name : email}
                        className="object-cover !w-16 !h-16 rounded-full"
                      />
                    )}
                  </div>
                ),
              },
              {
                accessor: "first_name",
                title: "First Name",
                render: ({ first_name }) => (
                  <div className="w-full">
                    <p>{first_name ? first_name : "--"}</p>
                  </div>
                ),
              },
              {
                accessor: "email",
                title: "Email",
                render: ({ email }) => (
                  <div className="w-full">
                    <p>{email ? email : "--"}</p>
                  </div>
                ),
              },
              {
                accessor: "mobileNo",
                title: "Mobile Number",
                render: ({ mobileNo }) => (
                  <div className="w-full">
                    <p>{mobileNo ? mobileNo : "--"}</p>
                  </div>
                ),
              },
              {
                accessor: "gender",
                title: "Gender",
                render: ({ gender }) => (
                  <div className="w-full">
                    <p>{gender ? gender : "--"}</p>
                  </div>
                ),
              },
              {
                accessor: "city",
                title: "City",
                render: ({ city }) => (
                  <div className="w-full">
                    <p>{city ? city : "--"}</p>
                  </div>
                ),
              },
              {
                accessor: "action",
                title: "Action",
                titleClassName: "!text-center overflow-hidden w-fit",
                render: ({ _id, blockByAdmin }) => (
                  <div className="flex items-center justify-center mx-auto gap-2 w-full me-3">
                    <div
                      onClick={() => handleNavigate(_id)}
                      className="cursor-pointer h-8 w-8 px-1 flex items-center justify-center border border-primary/20 rounded"
                    >
                      <AiFillEye className="h-6 w-6 text-primary/80" />
                    </div>
                    {blockByAdmin ? (
                      <div
                        className="cursor-pointer h-8 px-1 w-[100px] flex items-center gap-1 justify-center border border-primary/20 rounded text-primary hover:text-primary/70"
                        onClick={() => handleBlockUnblock("unblock", _id)}
                      >
                        <FaUserAlt className="h-5 w-5 duration-300" />{" "}
                        <span className="font-bold">Unblock</span>
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer h-8 px-1 w-[100px] flex items-center gap-1 justify-center border border-primary/20 rounded text-primary hover:text-primary/70"
                        onClick={() => handleBlockUnblock("block", _id)}
                      >
                        <FaUserAltSlash className="h-6 w-6 duration-300" />{" "}
                        <span className="font-bold">Block</span>
                      </div>
                    )}

                    <div
                      className="cursor-pointer h-8 w-8 px-1 flex items-center justify-center border border-primary/20 rounded"
                      onClick={() => handleDocumentOpen("delete", _id)}
                    >
                      <AiFillDelete className="h-5 w-5 hover:text-danger/70 text-danger duration-300" />
                    </div>
                  </div>
                ),
              },
            ]}
            totalRecords={totalCount}
            recordsPerPage={pageSize}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            fetching={isLoading}
            loading={isLoading}
          />
          <CustomPagination
            total={totalCount}
            page={page}
            handleChange={handleChange}
            dataPerPage={pageSize}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
          />
        </div>
      </div>

      {/* Add And Edit Modal */}
      {documentModal && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden  max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex justify-between items-center flex-col pb-8 gap-4">
                    <h5 className="tab:text-xl text-base font-semibold">
                      {isUpdate ? "Update" : "Add"} Category
                    </h5>
                    <div className="w-full">
                      <label>Category</label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter Category"
                        name="name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </div>

                    <div className="w-full">
                      <label>Image</label>
                      <input
                        type="file"
                        className="form-input"
                        placeholder="Enter Category"
                        name="name"
                        onChange={handleImageChange}
                      />
                    </div>

                    {selectedImage && (
                      <img
                        src={selectedImage}
                        className="w-[150px] object-cover rounded-md"
                      />
                    )}
                  </div>
                  <div className="flex items-center gap-4">
                    <button
                      type="submit"
                      onClick={() => handleClickClose("add")}
                      className="btn bg-white text-danger font-bold border border-danger hover:bg-danger hover:text-white duration-200 text-sm w-full"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn bg-primary text-white font-bold border border-primary hover:bg-transparent hover:text-primary duration-200 text-sm w-full"
                    >
                      {isUpdate ? "Update" : "Add"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Delete Modal */}
      {deleteModals && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <div className="flex justify-between items-center flex-col pb-6 gap-7">
                  <div className="border-2 border-danger p-2.5 rounded-full">
                    <IoCloseOutline className="text-danger text-4xl" />
                  </div>
                  <h5 className="tab:text-xl text-base font-semibold">
                    Delete Conformation
                  </h5>
                  <p>Are you sure you want to delete this user ?</p>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="submit"
                    onClick={() => handleClickClose("delete")}
                    className="btn bg-white text-primary font-bold border border-primary hover:bg-primary hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleDelete}
                    className="btn bg-danger text-white font-bold border border-danger hover:bg-transparent hover:text-danger duration-200 text-sm w-full"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Block/Unblock */}
      {isBlockUnblockOpen && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <div className="flex justify-between items-center flex-col pb-6 gap-7">
                  <div
                    className={`border-2 ${
                      blockUnBlockText === "block"
                        ? "border-danger"
                        : "border-success"
                    } p-2.5 rounded-full`}
                  >
                    {blockUnBlockText === "block" ? (
                      <FaUserAltSlash className="text-danger text-4xl" />
                    ) : (
                      <FaUserAlt className="text-success text-4xl" />
                    )}
                  </div>
                  <h5 className="tab:text-xl text-base font-semibold">
                    {blockUnBlockText === "block"
                      ? "Block Conformation"
                      : "Unblock Conformation"}
                  </h5>
                  <p>
                    {blockUnBlockText === "block"
                      ? "Are you sure you want to block this user ?"
                      : "Are you sure you want to unblock this user ?"}
                  </p>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="submit"
                    onClick={() => handleClickClose("blockUnBlock")}
                    className="btn bg-white text-primary font-bold border border-primary hover:bg-primary hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleBlock}
                    className={`btn ${
                      blockUnBlockText === "block"
                        ? "bg-danger border-danger hover:text-danger"
                        : "bg-success border-success hover:text-success"
                    } text-white font-bold border hover:bg-transparent duration-200 text-sm w-full`}
                  >
                    {blockUnBlockText === "block" ? "Block" : "Unblock"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserTable;
