import { lazy } from "react";
import UserTable from "../pages/user/UserTable.js";
import UserDetails from "../pages/user/UserDetails.js";
import VerifyOtp from "../pages/Authentication/VerifyOtp.js";
const Error = lazy(() => import("../components/Error"));
const LoginBoxed = lazy(() => import("../pages/Authentication/LoginBoxed.js"));

const routes = [
  {
    path: "/user",
    element: <UserTable />,
  },
  {
    path: "/user/:id",
    element: <UserDetails />,
  },
  //Authentication
  {
    path: "/login",
    element: <LoginBoxed />,
    layout: "blank",
  },
  {
    path: "/verify-otp",
    element: <VerifyOtp />,
    layout: "blank",
  },

  {
    path: "*",
    element: <Error />,
    layout: "blank",
  },
];

export { routes };
