import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import { useState, useEffect } from "react";
import IconMenuChat from "../Icon/Menu/IconMenuChat";
import IconMenuUsers from "../Icon/Menu/IconMenuUsers";
import IconLogout from "../Icon/IconLogout";
import logoImage from "../../assets/images/logo.svg";
import IconQuestion from "../Icon/IconQuestion";
import { MdCollections } from "react-icons/md";
import { BsFillCollectionFill } from "react-icons/bs";

const Sidebar = () => {
  const [currentMenu, setCurrentMenu] = useState("");
  const [errorSubMenu, setErrorSubMenu] = useState(false);
  const themeConfig = useSelector((state) => state.themeConfig);
  const semidark = useSelector((state) => state.themeConfig.semidark);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleMenu = (value) => {
    setCurrentMenu((oldValue) => {
      return oldValue === value ? "" : value;
    });
  };

  useEffect(() => {
    const selector = document.querySelector(
      '.sidebar ul a[href="' + window?.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const ul = selector.closest("ul.sub-menu");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line
  }, [location]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <div className={semidark ? "dark" : ""}>
      <nav
        className={` sidebar fixed min-h-screen overflow-hidden h-full top-0 bottom-0 w-[260px] z-50 transition-all duration-300 border-r border-[#E2E2E2] ${
          semidark ? "text-white-dark" : ""
        }`}
      >
        <div className="h-full">
          <div className="flex justify-center items-center px-4 py-3">
            <NavLink
              to="/"
              className="main-logo flex items-center gap-3 shrink-0"
            >
              <img
                className="w-8 ml-[5px] flex-none"
                src={logoImage}
                alt="logo"
              />
              {/* <span className="text-xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light">
                SLASH STAR
              </span> */}
              {themeConfig.sidebar && (
                <div className="ltr:ml-1.5 rtl:mr-1.5 text-lg font-semibold align-middle lg:inline">
                  Shhinder
                </div>
              )}
            </NavLink>
          </div>
          <PerfectScrollbar className="h-[calc(100vh-50px)] relative">
            <div className="flex flex-col justify-between h-full">
              <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                <li className="nav-item">
                  <ul>
                    <li className="nav-item">
                      <NavLink to="/user" className="group">
                        <div className="flex items-center">
                          <IconMenuUsers className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3  dark:text-[#506690] dark:group-hover:text-white-dark">
                            {/* {t("mailbox")} */}
                            User
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink to="/category" className="group">
                        <div className="flex items-center">
                          <IconMenuChat className="group-hover:!text-primary shrink-0" />
                          <span className="ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">
                            Category
                          </span>
                        </div>
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
              </ul>
              <ul className="px-4">
                <li className="nav-item">
                  <div
                    onClick={handleLogout}
                    className="group text-danger cursor-pointer mb-6"
                  >
                    <div className="flex items-center w-full gap-6">
                      <IconLogout className="rotate-90 shrink-0 !text-danger group-hover:!text-primary" />
                      <span className="text-danger group-hover:text-primary font-semibold">
                        {/* {t("scrumboard")} */}
                        Sign Out
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
