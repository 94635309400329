import serverCall from "../serverCall";

const getAllUser = (page, limit) => {
  const response = serverCall.get(
    `/admin/users/list?page=${page}&limit=${limit}`
  );
  return response;
};

const blockUser = (userId) => {
  const response = serverCall.post(`/admin/users/block?userId=${userId}`);
  return response;
};

const unBlockUser = (userId) => {
  const response = serverCall.post(`/admin/users/unBlock?userId=${userId}`);
  return response;
};

const blockUserList = () => {
  const response = serverCall.get(`/admin/block/usersList`);
  return response;
};

const deleteUser = (userId) => {
  const response = serverCall.delete(`/admin/delete/user?userId=${userId}`);
  return response;
};

const UserService = {
  getAllUser,
  blockUser,
  unBlockUser,
  blockUserList,
  deleteUser,
};

export default UserService;
