import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../store/themeConfigSlice";
import Dropdown from "../Dropdown";
import IconMenu from "../Icon/IconMenu";
import IconLogout from "../Icon/IconLogout";
import Logo from "../../assets/images/logo.svg";
import IconCaretsDown from "../Icon/IconCaretsDown";

const HeaderComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const selector = document.querySelector(
      'ul.horizontal-menu a[href="' + window?.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const all = document.querySelectorAll(
        "ul.horizontal-menu .nav-link.active"
      );
      for (let i = 0; i < all.length; i++) {
        all[0]?.classList.remove("active");
      }
      const ul = selector.closest("ul.sub-menu");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".nav-link");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add("active");
          });
        }
      }
    }
  }, [location]);

  const isRtl = useSelector((state) => state.themeConfig.rtlClass) === "rtl";

  const themeConfig = useSelector((state) => state.themeConfig);
  const sidebarToggle = useSelector((state) => state.themeConfig.sidebar);
  console.log("sidebarToggle", sidebarToggle);
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <header
      className={`z-40 ${
        themeConfig.semidark && themeConfig.menu === "horizontal" ? "dark" : ""
      }`}
    >
      <div className="shadow-sm">
        <div className="relative bg-white flex w-full items-center justify-between px-5 py-2.5 dark:bg-black">
          <button
            type="button"
            className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary lg:flex hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
            onClick={() => dispatch(toggleSidebar())}
          >
            <IconCaretsDown
              className={`w-5 h-5 duration-300 ${
                sidebarToggle ? "rotate-90" : "-rotate-90"
              }`}
            />
          </button>
          <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">
            <Link to="/" className="main-logo flex items-center shrink-0">
              <img
                className="w-8 ltr:-ml-1 rtl:-mr-1 inline"
                src={Logo}
                alt="logo"
              />
              <span className="text-2xl ltr:ml-1.5 rtl:mr-1.5  font-semibold  align-middle hidden md:inline dark:text-white-light transition-all duration-300">
                Shhinder
              </span>
            </Link>
            <button
              type="button"
              className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
              onClick={() => {
                dispatch(toggleSidebar());
              }}
            >
              <IconMenu className="w-5 h-5" />
            </button>
          </div>

          <div>
            <div className="dropdown shrink-0 flex justify-end rounded-full border border-dark/30 p-0.5">
              <Dropdown
                offset={[0, 8]}
                placement={`${isRtl ? "bottom-start" : "bottom-end"}`}
                btnClassName="relative group block"
                button={
                  <img
                    className="w-8 h-8 rounded-full object-contain saturate-50 group-hover:saturate-100"
                    src={Logo}
                    alt="userProfile"
                  />
                }
              >
                <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                  <li className="border-t border-white-light dark:border-white-light/10">
                    <div
                      onClick={handleLogout}
                      className="text-danger !py-3 cursor-pointer flex ps-4 hover:bg-gray-300/50"
                    >
                      <IconLogout className="w-4.5 h-4.5 ltr:mr-2 rtl:ml-2 rotate-90 shrink-0" />
                      Sign Out
                    </div>
                  </li>
                </ul>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
